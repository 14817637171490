import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'base',
    component: () => import('@/views/Base.vue'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        alias: [''],
      },
      {
        path: 'learn',
        children: [
          {
            path: '',
            name: 'learn',
            component: () => import('@/views/learn/Learn.vue'),
            alias: ['study'],
          },
        ],
      },
      {
        path: 'activity',
        children: [
          {
            path: '',
            name: 'activity',
            component: () => import('@/views/activity/Activity.vue'),
          },
          {
            path: ':activityId',
            name: 'activity-detail',
            component: () => import('@/views/activity/ActivityDetail.vue'),
          },
        ],
      },
      {
        path: 'invite',
        children: [
          {
            path: 'recruit/:activityId',
            name: 'invite-recruit',
            component: () => import('@/views/invite/Recruit.vue'),
          },
          {
            path: 'poster/:activityId',
            name: 'invite-poster',
            component: () => import('@/views/invite/Poster.vue'),
          },
        ],
      },
      {
        path: 'learn',
        name: 'learn',
        component: () => import('@/views/learn/Learn.vue'),
      },
      {
        path: '/learn/summary/:learnId',
        name: 'learn-summary',
        component: () => import('@/views/learn/Summary.vue'),
      },
      {
        path: '/learn/content/:learnId/:contentId?',
        name: 'learn-content',
        component: () => import('@/views/learn/Content.vue'),
      },
      {
        path: 'practice',
        name: 'practice',
        component: () => import('@/views/Practice.vue'),
      },
      {
        path: 'aigc',
        name: 'aigc',
        component: () => import('@/views/Aigc.vue'),
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('@/views/About.vue'),
      },
    ],
  },
  {
    path: '/activity/:activityId/:subactivityId/:taskId',
    name: 'task-detail',
    component: () => import('@/views/UsePC.vue'),
  },
  {
    path: '/activity/quiz/:activityId/:subactivityId',
    name: 'activity-quiz',
    component: () => import('@/views/UsePC.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
  },
];

// export const router = createRouter({
//   history: createWebHistory(),
//   routes,
// });
